import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
	<Layout>
		<Seo title="SpinKain" />
		<div className="container my-5">

			<div className="container">
				<h1>SpinKain is a spinning wheel-based Filipino dish randomizer. </h1>
				&nbsp;
				<p>
				It is a fun project of the Ytinad company that is aimed to provide users with ideas on what type of Filipino dish to prepare or buy. 
				</p>
				<p>Use it for entertainment purposes only.</p>
			</div>
		</div>
	</Layout>
)

export default AboutPage
